.legend{
    position: absolute;
    top: 0px;
    left: 15px;
    /* transform: translateX(-50%); */
    width: auto;
    max-width: 90%;
    border-radius: 10px;
    background: #000;
    color: #fff;
    padding: 10px 20px;
    font-family: 'Proto';
    font-size: 14px;
    text-align: center;
    opacity: 0.75;
}

@media (max-width: 768px) {
    .legend{
        font-size: 7px;
        top: 0px;
        left: 5px;
        padding: 5px 10px;
        border-radius: 5px;
    }
}