a {
    text-decoration: none;
}

.button {
    background-color: #00aaaa;
    color: #111111;
    font-family: Proto;
    font-size: 20px;
    height: 50px;
    width: auto;
    border-radius: 10px;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button_img {
    height: 30px;
    width: auto;
    margin-right: 10px;
}

.button2 {
    background-color: #00aaaa;
    color: #111111;
    font-family: Proto;
    font-size: 14px;
    height: 45px;
    width: 325px;
    border-radius: 10px;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button_img2 {
    height: 15px;
    width: auto;
    margin-right: 10px;
}

.button_img2_disabled {
    height: 15px;
    width: auto;
    margin-right: 10px;
    filter: invert(0.7);
}

.button2_disabled {
    background-color: #333333;
    color: #aaaaaa;
    font-family: Proto;
    font-size: 14px;
    height: 45px;
    width: 325px;
    border-radius: 10px;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    cursor:not-allowed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: line-through;
}

.button_support {
    background-color: #FFE01A;
    color: #111111;
    font-family: Proto;
    font-size: 14px;
    height: 45px;
    width: 225px;
    border-radius: 10px;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom: 10px;
    z-index: 1000;    
    margin-left: 99.5%;
    transform: translateX(-100%);
}

.button_img_support {
    height: 15px;
    width: auto;
    margin-right: 10px;
    filter: brightness(10%) sepia(1)
}

.button_res {
    background-color: #222222;
    color: #555555;
    font-family: Proto;
    font-size: 14px;
    height: 45px;
    width: 125px;
    border-radius: 10px;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button_download {
    background-color: #00aaaa;
    color: #111111;
    font-family: Proto;
    font-size: 14px;
    height: 45px;
    width: 150px;
    border-radius: 10px;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button_download_disabled {
    background-color: #333333;
    color: #aaaaaa;
    font-family: Proto;
    font-size: 14px;
    height: 45px;
    width: 150px;
    border-radius: 10px;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    cursor:not-allowed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button_submit_disabled {
    background-color: #333333;
    color: #aaaaaa;
    font-family: Proto;
    font-size: 14px;
    height: 45px;
    width: 325px;
    border-radius: 10px;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    cursor:not-allowed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .button_support {
        width: 120px;
        height: auto;
        margin-left: 97.5%;
        transform: translateX(-100%);
        font-size: 10px;
    }
}