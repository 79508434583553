.contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    align-items: center;
    width: auto;
    height: 50px;
    padding: 10px 10px;
    background-color: #222222;
    position: absolute;
    left: 0;
    right: 0;
}

.img {
    height: 30px;
    width: auto;
}