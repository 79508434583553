.div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.div2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: flex-start;
}

.h1, .link {
    font-family: Ubuntu;
    font-size: 50px;
    color: #00aaaa;
    text-align: center;
    text-decoration: none;
}

.intro {
    font-family: Proto;
    font-size: 35px;
    color: #00aaaa;
}

.title {
    font-family: Proto;
    font-size: 26px;
    color: #ffffff;
    text-align: center;
}

.desc {
    font-family: Proto;
    font-size: 16px;
    color: #aaaaaa;
    text-align: center;
}