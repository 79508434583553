body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Proto';
  src: url('fonts/Proto Mono Regular.ttf') format('truetype');
}

/* Scrollbar styles for WebKit browsers (Safari, Chrome, etc.) */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}
  
::-webkit-scrollbar-track {
  background: transparent; /* Color of the scrollbar track */
}
  
::-webkit-scrollbar-thumb {
  background-color: #555; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners of the thumb */
}

/* Scrollbar styles for Firefox */
/* Note: Firefox does not support customizing the scrollbar appearance */

/* Scrollbar styles for IE and Edge */
::-ms-scrollbar {
  width: 8px; /* Width of the scrollbar */
}
  
::-ms-scrollbar-track {
  background: transparent; /* Color of the scrollbar track */
}
  
::-ms-scrollbar-thumb {
  background-color: #555; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners of the thumb */
}