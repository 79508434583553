.navbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #00aaaa;
    height: 150px;
}

.navbar_img {
    width: 100px;
    height: 100px;
    justify-self: center;
}